import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout';
import YoutubeEmbed from "../components/YoutubeEmbed";
// import 'react-responsive-modal/styles.css';
// import { Modal } from 'react-responsive-modal';
import Seo from '../components/seo';

export const query = graphql`
    query($id: ID!) {
        wpcontent {
            vptWebcastUsaS2(id: $id) {
                title
                uri
                excerpt
                content
                webinardate
                youtubeId
                seo {
                    metaDesc
                    canonical
                    title
                    opengraphTitle
                    opengraphDescription
                    opengraphType
                    twitterTitle
                    twitterDescription
                }
            }
            vptWebcastsUsaS2(first: 2) {
                nodes {
                    id
                    title
                    uri
                    webinardate
                    youtubeId
                    speaker1
                    speaker1Profile
                    speaker2
                    speaker2Profile
                    thumbnail
                }
            }
        }
    }
`

const WebinarTemplate = ({ data }) => {
    const webinar = data.wpcontent.vptWebcastUsaS2
    const metaDesc = data.wpcontent.vptWebcastUsaS2.seo.metaDesc
    const pageTitle = data.wpcontent.vptWebcastUsaS2.seo.title
    // const webinars = data.wpcontent.vptWebcastUsaS2.nodes
    // const pageType = data.wpcontent.webinar.seo.opengraphType
    // console.log(webinars)
    return (
        <Layout className="webinar-detail">
            <Seo title={pageTitle}
            description={metaDesc}
            />
            <section className="bg-purple-100  mx-auto py-10 sm:py-20 md:py-20 relative mb-20">
                <div className="container md:flex md:flex-col justify-center items-center lg:flex-row lg:justify-between max-w-6xl text-center lg:text-left px-6">
                   <div className = " w-full justify-center lg:justify-start">
                       <div className="box-label1 hover:bg-purple-800 cursor-auto"><a href="/webinars/vantage-point-webcast-usa-s2/" className="text-gray-500">Vantage Point Webcasts USA - Season 2</a></div>
                        <h1 className = "section-title-v1 mb-5">{webinar.title}</h1>
                        <p className="uppercase">Streamed on {webinar.webinardate}</p>
                   </div>
                </div>
            </section>
            <section className="container max-w-6xl pb-20 px-6">
                <div className="md:flex md:items-center">
                    <div className="md:w-1/2">
                        <h2 class="md:mb-10 text-2xl font-semibold text-gray-900 sm:text-4xl">A Few key takeaways</h2>
                        <ul className="form-bullets list-inside orangebullets md:mr-5" dangerouslySetInnerHTML={{ __html: webinar.excerpt }} />
                    </div>
                    <div className="md:w-1/2">
                        <YoutubeEmbed embedId={webinar.youtubeId} />
                    </div>
                </div>
            </section>
            <section className="container max-w-6xl px-6 md:pt-10">
                <div dangerouslySetInnerHTML={{ __html: webinar.content }} />
            </section>
            <section>
                <div class="container max-w-7xl mx-auto px-6 py-10 md:py-20 md:pb-40" id="hosts">
                    <h2 class="font-bold text-3xl md:text-4xl xl:text-6xl 2xl:text-7xl text-gray-500 py-9">
                        About the Hosts
                    </h2>
                    <div class="max-w-3xl mt-10 grid grid-cols-1 gap-5 md:gap-9 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                        <div class="card-1 w-full mb-1 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out hover:-translate-y-2 relative advisors-wrapper">
                            <div class="relative">
                                <a href="https://www.linkedin.com/in/chesterelton/" target="_blank" rel="noopener">
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1645441047/gatsbycms/uploads/2022/02/chester-elton-advisors.png" alt="Chester Elton, Advisor, Vantage Circle" width="366" height="422"/>
                                    </a><a href="https://www.linkedin.com/in/chesterelton/" target="_blank" rel="noopener"><div class="bg-blue w-10 h-10 absolute right-5 top-5 rounded-full flex justify-center items-center z-10"><svg id="Bold" enable-background="new 0 0 24 24" height="20" viewBox="0 0 24 24" width="20"><path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z" fill="#fff"></path><path d="m.396 7.977h4.976v16.023h-4.976z" fill="#fff"></path><path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z" fill="#fff"></path></svg></div></a>
                                    <div class="text-center absolute bottom-0 p-4">
                                        <p class="text-white font-bold text-lg sm:text-2xl mb-0 sm:mb-2">Chester Elton</p>
                                        <p class="text-white text-sm sm:text-base mb-2">#1 Bestselling Business Author, Leadership Expert, Organizational Culture Expert, Employee Engagement and Teamwork Expert, Global Keynote Speaker.</p>
                                    </div>
                                
                            </div>
                            <div class="absolute adv-detail">
                                    <p class="text-white text-sm sm:text-base mb-0">Chester Elton is a #1 Bestselling Business Author, #4 among the world's top leadership experts and #2 among the world's top organizational culture experts. He is a member of Marshall Goldsmith 100 Coaches, an Executive Coach, Keynote Speaker, and Founder of the #findyourgratitude Community. In the last two decades, Chester has helped some of the world's most successful businesses engage their employees to execute on strategy, vision, and values.</p>
                            </div>
                        </div>
                        <div class="card-1 w-full mb-1 rounded-lg overflow-hidden shadow-lg transform transition duration-500 ease-in-out hover:-translate-y-2 relative advisors-wrapper">
                            <div class="relative">
                                <a href="https://www.linkedin.com/in/adriangostick/" target="_blank" rel="noopener">
                                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/v1645441047/gatsbycms/uploads/2022/02/Adrian-advisors.png" alt="Adrian Gostick, Advisor, Vantage Circle" width="366" height="422"/>
                                    </a><a href="https://www.linkedin.com/in/adriangostick/" target="_blank" rel="noopener"><div class="bg-blue w-10 h-10 absolute right-5 top-5 rounded-full flex justify-center items-center z-10"><svg id="Bold" enable-background="new 0 0 24 24" height="20" viewBox="0 0 24 24" width="20"><path d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z" fill="#fff"></path><path d="m.396 7.977h4.976v16.023h-4.976z" fill="#fff"></path><path d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z" fill="#fff"></path></svg></div></a>
                                    <div class="text-center absolute bottom-0 p-4">
                                        <p class="text-white font-bold text-lg sm:text-2xl mb-0 sm:mb-2">Adrian Gostick</p>
                                        <p class="text-white text-sm sm:text-base mb-2">#1 New York Times bestselling author, Corporate Culture Expert, Engagement Expert, Leadership Expert, Managing Change Expert, Global Keynote Speaker.</p>
                                    </div>
                                
                            </div>
                            <div class="absolute adv-detail">
                                    <p class="text-white text-sm sm:text-base mb-0">Adrian Gostick is a global thought leader in the fields of corporate culture, leadership, and engagement. He is founder of the workplace training and consulting company The Culture Works and author of the New York Times, USA Today and Wall Street Journal bestsellers All In, The Carrot Principle, Leading with Gratitude, and Anxiety at Work. In 2021, Adrian was ranked as a top 10 Global Guru in Leadership and Organizational Culture. He is a member of Marshall Goldsmith's MG100 'Pay it Forward' Coaching Cohort.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <div className="container max-w-6xl px-6 py-20">
                <div className="flex justify-between bg-purple-100 p-7 rounded-lg items-center mb-10">
                    <div className="text-2xl font-bold text-gray-900 sm:text-4xl">Up next</div>
                    <Link to="/webinars/" className="hover:underline">View All Webinars</Link>
                </div>
                <div className="mt-10 md:mt-0 grid grid-cols-1 sm:gap-3 md:gap-9 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                    {webinars.map(webinar => (
                        <article key={webinar.id}>
                            <div className="relative z-1 bg-white p-5 card-1 w-full mb-5 md:mb-0 rounded-xl overflow-hidden shadow-lg">
                                <Link to={`${webinar.uri}`} >
                                    <img className="p-0 rounded-lg webinar-video-thumb" src={`${webinar.thumbnail}`} alt={webinar.title} />
                                </Link>
                                <Link to={`${webinar.uri}`} >
                                    <div className="bg-white py-6 pb-2 h-full topcard no-br">
                                        <p className="text-orange text-base">[On-demand-recording]</p>
                                        <h2 className="box-title-v1 font-bold mb-3 line-clamp-1 -mt-3" dangerouslySetInnerHTML={{ __html: webinar.title }} />
                                        <p className="box-subtitle mb-2 uppercase">On <span dangerouslySetInnerHTML={{ __html: webinar.webinardate }} /></p>
                                        <div className="with-sep text-gray-200 text-base">With</div>
                                        <div className="md:flex justify-between">
                                            <div className="box-subtitle w-fl md:h-12">
                                                <span className="font-bold text-lg">{webinar.speaker1}</span> <br/> <span>{webinar.speaker1Profile}</span>
                                            </div>
                                            {webinar.speaker2 && <div className="box-subtitle w-fl md:h-12">
                                                <span className="font-bold text-lg">{webinar.speaker2}</span> <br/> <span>{webinar.speaker2Profile}</span>
                                            </div>}
                                        </div>
                                        
                                    </div>
                                </Link>
                            </div>
                        </article>
                    ))}
                </div>
            </div>  */}

            <section className="bottom-cta m-6 md:m-0 relative z-1">
                <div className=" p-10 md:p-14 relative z-9 overflow-hidden" style={{"background": "#414171"}}>
                    <div  className="bottom-cta-content text-center text-white">
                        <div className="section-title-white">Find Out How Vantage Circle Works</div>
                        <div className="mt-5 sm:mt-6 md:mt-6 flex justify-center">
                            <div>
                                <a className="vc-ghost-btn-cta-banner" href="/request-demo/">Schedule a Free Demo</a>
                            </div>
                        </div>
                    </div>
                    <img loading="lazy" className="cta-dot-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622890994/gatsbycms/uploads/2021/06/circle-white-compressed.png"  alt="VC white dots" width="90" height="90"/>
                    <img loading="lazy" className="cta-flower-clip" src="https://res.cloudinary.com/vantagecircle/image/upload/v1622874257/gatsbycms/uploads/2021/06/vc-flower.svg"  alt="VC flower" width="150" height="150"/>
                </div>                         
            </section>
            
        </Layout>
    )
}

export default WebinarTemplate



